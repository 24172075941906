export default function GoldenBar({ width, height }) {
  return (
    <svg
      className="golden-bar"
      width={width ? width : "149"}
      height={height ? height : "5"}
      viewBox="0 0 149 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="0.0103691"
        y1="1.50004"
        x2="148.01"
        y2="2.52314"
        stroke="url(#paint0_linear_301_154)"
        stroke-width="3"
      />
      <defs>
        <linearGradient
          id="paint0_linear_301_154"
          x1="-0.00345637"
          y1="3.49999"
          x2="147.996"
          y2="4.5231"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#EFAA2F" />
          <stop offset="0.506291" stop-color="#FCC162" />
          <stop offset="1" stop-color="#D18C27" />
        </linearGradient>
      </defs>
    </svg>
  );
}
