import "./App.css";
import GoldenBar from "./golden-bar";
import Quotes from "./quotes";

function App() {
  return (
    <div className="App">
      <section className="landing-page">
        <h1 className="brand-name">The Key</h1>
        <h6 className="tagline">THE CENTRE FOR SPECIAL EDUCATION</h6>
      </section>
      <section className="page-1">
        <img src="/assets/images/kids.png" alt="kids-image" />
        <div className="golden-border"></div>
        <div className="catch-phrase-container">
          <Quotes />
          <h4 className="catch-phrase">
            When you encounter a special child, bring them to The Key – where
            every child's potential is unlocked
          </h4>
          <GoldenBar />
          <div className="golden-border"></div>
        </div>
      </section>
      <section className="page-2">
        <h3>How we are different?</h3>
        <GoldenBar width="100" />
        <div className="content-container">
          <h4>Assessment and Evaluation</h4>
          <p>
            We assess student's abilities and needs through various evaluation
            methods to develop individualized education plans (IEP's).
          </p>
          <h4>Individualised Education Plans (IEP's)</h4>
          <p>
            We create and implement IEP's to each student's unique needs,
            outlining specific goals, accommodations, and modifications.
          </p>
          <h4>Instructions</h4>
          <p>
            We provide specialized instruction in academic subjects, life
            skills, and social skills, using various teaching methods and
            materials to meet diverse learning styles.
          </p>
          <h4>Collaboration</h4>
          <p>
            We collaborate with parents and school teachers, to enhance
            student's potential.
          </p>
          <h4>Behavior Management</h4>
          <p>
            We implement behavior management strategies to help students to
            develop appropriate social skills and to manage challenging
            behaviours.
          </p>
          <h4>Advocacy</h4>
          <p>
            We ensure OUR students receive appropriate services and support
            within the educational system.
          </p>
          <h4>Professional Development</h4>
          <p>
            We use new strategies, and technologies in special education to
            enhance the teaching practices.
          </p>
          <h4>Support and Counseling</h4>
          <p>
            We provide emotional support and counseling to students and their
            families, helping them navigate the challenges associated with
            difficulties.
          </p>
          <h4>Transition Planning</h4>
          <p>
            For older students, we assist with transition planning to prepare
            them for life after school, including post-secondary education,
            vocational training, and independent living.
          </p>
          <h4>Remedial Tutoring</h4>
          <p>
            Our remedial program trains tutors to provide personalized
            instruction, helping students overcome challenges and succeed.
          </p>
          <h4>Effective Learning Strategies</h4>
          <p>
            We provide learning strategies that cater to diverse needs,
            empowering students to thrive academically and beyond.
          </p>
          <h4>Adult Dyslexia Empowerment</h4>
          <p>
            Our approach supports adults with dyslexia, promoting lifelong
            learning and sustainable development.
          </p>
          <h4>Counseling Skills</h4>
          <p>
            Our program enhances counseling skills, enabling professionals to
            support individuals with dyslexia and their families effectively.
          </p>
        </div>
      </section>
      <section className="page-3">
        <div>
          <h2>Visit Us</h2>
          <div>
            <h3>Address</h3>
            <address>
              No 3/2, 39th street, Ashok Nagar, Chennai - 600083.
            </address>
          </div>
          <div>
            <h3>Contact</h3>
            <a href="tel:+919840980958" className="contact">
              +91 9840 980 958
            </a>
            <br />
            <a href="mailto:contact@thekey.school" className="contact">
              contact@thekey.school
            </a>
          </div>
        </div>
      </section>
    </div>
  );
}

export default App;
