export default function Quotes() {
  return (
    <svg
      className="quotes"
      viewBox="0 0 21 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.152 0.715997C9.35867 1.336 9.462 1.85266 9.462 2.266C9.462 2.638 9.4 3.134 9.276 3.754C6.50667 3.754 5.122 5.18 5.122 8.032C5.122 9.06533 5.65933 9.644 6.734 9.768C8.30467 9.892 9.09 10.9047 9.09 12.806C9.09 14.2527 8.73867 15.3687 8.036 16.154C7.37467 16.898 6.15533 17.27 4.378 17.27C1.93933 17.27 0.72 15.6167 0.72 12.31C0.72 9.00333 1.38133 6.25466 2.704 4.064C4.02667 1.832 6.176 0.715997 9.152 0.715997ZM20.374 0.715997C20.5807 1.336 20.684 1.85266 20.684 2.266C20.684 2.638 20.622 3.134 20.498 3.754C17.7287 3.754 16.344 5.18 16.344 8.032C16.344 9.06533 16.8813 9.644 17.956 9.768C19.5267 9.892 20.312 10.9047 20.312 12.806C20.312 14.2527 19.9607 15.3687 19.258 16.154C18.5967 16.898 17.3773 17.27 15.6 17.27C13.1613 17.27 11.942 15.6167 11.942 12.31C11.942 9.00333 12.6033 6.25466 13.926 4.064C15.2487 1.832 17.398 0.715997 20.374 0.715997Z"
        fill="url(#paint0_linear_299_137)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_299_137"
          x1="-3"
          y1="23"
          x2="25"
          y2="23"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#EFAA2F" />
          <stop offset="0.506291" stop-color="#FCC162" />
          <stop offset="1" stop-color="#D18C27" />
        </linearGradient>
      </defs>
    </svg>
  );
}
